<template>
  <ion-page>
    <div class="background--living" />
    <ion-header
      class="ion-no-border"
    >
      <ion-toolbar
        color="transparent"
      >
        <ion-item
          v-if="selectedView === '1'"
          slot="start"
          lines="none"
          fill="clear"
          color="transparent"
          size="large"
          class="close-modal-button"
          @click="$router.back()"
        >
          <img src="../assets/images/arrow-go-back-line.svg">
        </ion-item>
        <ion-item
          v-else
          slot="start"
          fill="clear"
          lines="none"
          color="transparent"
          size="large"
          class="close-modal-button"
          @click="selectedView = '1'"
        >
          <img src="../assets/images/arrow-go-back-line.svg">
        </ion-item>
        <ion-title
          v-if="selectedView === '1'"
          class="text--white text--capitalize text-18"
        >
          {{ $t('scenes') }}
        </ion-title>
        <ion-title
          v-else
          class="text--white text-18"
        >
          {{ editingScene.sceneName }}
        </ion-title>
        <ion-button
          v-if="selectedView === '2'"
          slot="end"
          fill="clear"
          color="transparent"
          class="close-modal-button"
          @click="presentActionSheet(editingScene)"
        >
          <i class="material-icons">tune</i>
        </ion-button>
      </ion-toolbar>
      <ion-segment
        v-if="selectedView === '2'"
        swipe-gesture="true"
        :value="selectedViewSceneDetail"
        style="width: 250px"
        @ionChange="selectedViewSceneDetail = $event.target.value"
      >
        <ion-segment-button value="1">
          <ion-label
            class="text-16"
          >
            {{ $t('devices') }}
          </ion-label>
        </ion-segment-button>
        <ion-segment-button value="2">
          <ion-label
            class="text-16"
          >
            {{ $t('securityButton') }}
          </ion-label>
        </ion-segment-button>
      </ion-segment>
    </ion-header>
    <ion-content class="ion-no-padding">
      <div v-if="selectedView === '1'">
        <div
          v-for="scene in scenes"
          :key="scene"
        >
          <ion-card
            :class="scene.scenePicture"
            @click="editingScene = scene; selectedView = '2'"
          >
            <ion-card-title class="text--white text-22">
              {{ scene.sceneName }}
            </ion-card-title>
          </ion-card>
        </div>
        <ion-fab
          slot="fixed"
          vertical="bottom"
          horizontal="end"
        >
          <ion-fab-button @click="addSceneModal">
            <ion-icon
              :icon="addOutline"
              class="text--black"
            />
          </ion-fab-button>
        </ion-fab>
      </div>
      <div v-else-if="selectedView === '2'">
        <div
          v-if="selectedViewSceneDetail === '1'"
          class="scenedetails"
        >
          <div class="text-left">
            <ion-text class="text--white ion-padding-start text--uppercase text--bold text-14">
              {{ $t('Control Devices') }}
            </ion-text>
          </div>
          <ion-grid>
            <ion-row>
              <ion-col
                v-for="device in editingScene.sceneSettings.devices"
                :key="device"
                size="6"
              >
                <div class="device rounded-corners">
                  <div class="pt-16">
                    <img
                      class="device-image"
                      :src="require('../assets/devices/' + device.zigbeeModel + '.png')"
                    >
                  </div>
                  <div class="text--white text-16">
                    {{ device.deviceName }}
                  </div>
                  <div class="text-12 mt-10 text--gray">
                    {{ deviceRoom(device.propertyRoomId) }}
                  </div>
                  <div class="mt-15">
                    <ion-toggle
                      :checked="device.switchgear === 'on'"
                      :value="device.switchgear === 'on' ? 'off' : 'on'"
                      @ionChange="updateDevicesOnOffValueScenes($event.target.value, device.ieee, editingScene)"
                    />
                  </div>
                </div>
              </ion-col>
            </ion-row>
          </ion-grid>
        </div>
        <div
          v-else
          class="ion-padding-start ion-padding-end scene-security"
        >
          <ion-item
            class="ion-no-padding"
            style="margin-top: -10px;"
            lines="none"
            color="transparent"
          >
            <ion-text
              slot="start"
              class="text--white text--uppercase text--bold text-14"
            >
              {{ $t('securityButton') }}
            </ion-text>
            <ion-text
              class="text--bold text-14"
              :class="editingScene.alarm ? 'text--primary' : 'text--white' "
            >
              {{ editingScene.alarm ? $t('ENABLED') : $t('DISABLED') }}
            </ion-text>
            <ion-toggle
              slot="end"
              v-model="editingScene.alarm"
              :value="editingScene.alarm ? false : true"
              @ionChange="turnOnOffSecurity($event.target.value, editingScene.id)"
            />
          </ion-item>
          <div
            v-if="editingScene.alarm"
            class="text-left"
          >
            <span class="text--gray text-14">{{ $t('sceneAlarmInstructions') }} </span>
            <ion-list
              v-for="(group, propertyRoomId) in groupsSecurity"
              :key="propertyRoomId"
              class="text--white"
            >
              <!-- <div class="d-flex align-items-center justify-content-between">
                <ion-label class="text--gray text-14 text--uppercase">
                  {{ deviceRoom(propertyRoomId) }}
                </ion-label>
              </div> -->

              <div
                v-for="item in group"
                :key="item"
              >
                <ion-item
                  class="text--white device-item"
                  color="transparent"
                  lines="none"
                >
                  <img
                    slot="start"
                    style="width: 40px; margin-left: -10px;"
                    :src="require('../assets/devices/' + item.zigbeeModel + '.png')"
                  >
                  <ion-text>
                    {{ item.deviceName }}<br>
                    <span class="text--gray text-12">{{ deviceRoom(propertyRoomId) }}</span>
                  </ion-text>
                  <ion-toggle
                    slot="end"
                    v-model="item.enabled"
                    :value="item.enabled ? false : true"
                    @ionChange="enableSecurityDevice($event.target.value, item.ieee, editingScene.id)"
                  />
                </ion-item>
              </div>
            </ion-list>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  closeOutline,
  cogOutline,
  addOutline
} from 'ionicons/icons'
import { modalController, actionSheetController, alertController } from '@ionic/vue'
import addDeviceToSceneModal from '../components/Scenes/AddDevicesToSceneModal'
import editSceneModal from '../components/Scenes/EditSceneModal'
import addSceneModal from '../components/Scenes/AddSceneModal'

export default ({
  data () {
    return {
      closeOutline,
      cogOutline,
      addOutline,
      selectedView: '1',
      selectedViewSceneDetail: '1',
      sceneName: '',
      editingScene: null
    }
  },
  computed: {
    scenes () {
      return this.$store.state.propertyScenes
    },
    propertyDevices () {
      return this.$store.state.propertyDevices
    },
    groupsSecurity () {
      const key = 'propertyRoomId'
      const result = {}
      this.editingScene.sceneSettings.security.forEach(item => {
        if (item.security) {
          if (!result[item[key]]) {
            result[item[key]] = []
          }
          result[item[key]].push(item)
        }
      })
      return result
    }
  },
  methods: {
    deviceRoom (id) {
      const roomId = Number(id)
      const rooms = this.$store.state.propertyRooms
      const found = rooms.find(element => element.id === roomId)
      return found ? found.roomName : '--'
    },
    updateDevicesOnOffValueScenes (newValue, ieee, scene) {
      const sceneName = scene.sceneName
      const sceneId = scene.id
      this.$store.dispatch('updateDevicesOnOffValueScenes', { newValue, ieee, sceneName, sceneId })
    },
    removeDeviceFromScene (ieee, scene) {
      const sceneName = scene.sceneName
      const sceneId = scene.id
      this.$store.dispatch('removeDeviceFromScene', { ieee, sceneName, sceneId })
    },
    turnOnOffSecurity (value, sceneId) {
      let sceneSetting = false
      if (value === 'true') {
        this.editingScene.alarm = true
        sceneSetting = true
        this.$store.state.propertyDevices.forEach(item => {
          if (item.security) {
            const sceneSecurityDevice = {
              id: item.id,
              ieee: item.ieee,
              security: item.security,
              ep: item.ep,
              deviceName: item.deviceName,
              propertyRoomId: item.propertyRoomId,
              zigbeeModel: item.zigbeeModel,
              enabled: false
            }

            this.editingScene.sceneSettings.security.push(sceneSecurityDevice)
          }
        })
      } else {
        this.editingScene.alarm = false
        sceneSetting = false
        this.editingScene.sceneSettings.security = []
      }
      const sceneSecurityObject = this.editingScene.sceneSettings.security
      this.$store.dispatch('turnOnOffSceneSecurity', { sceneSetting, sceneSecurityObject, sceneId })
    },
    enableSecurityDevice (value, device, sceneId) {
      const securityDeviceIndex = this.editingScene.sceneSettings.security.findIndex(item => item.ieee === device)
      if (value === 'true' && securityDeviceIndex !== -1) {
        this.editingScene.sceneSettings.security[securityDeviceIndex].enabled = true
      } else if ((value === 'false' && securityDeviceIndex !== -1)) {
        this.editingScene.sceneSettings.security[securityDeviceIndex].enabled = false
      } else {
        console.log('error')
      }
      const sceneSecurityObject = this.editingScene.sceneSettings.security
      this.$store.dispatch('updateSecurityInScene', { sceneSecurityObject, sceneId })
    },
    deleteScene (scene) {
      if (this.scenes.length <= 1) {
        this.cannotDeleteLastScene()
      } else if (scene.sceneActive) {
        this.cannotDeleteActiveScene()
      } else {
        this.$store.dispatch('deleteScene', scene).then(response => {
          if (response) {
            this.selectedView = '1'
          }
        })
      }
    },
    // modals ====
    addDeviceToSceneModal (scene, type) {
      return modalController
        .create({
          component: addDeviceToSceneModal,
          cssClass: 'devices-modal',
          swipeToClose: true,
          showBackdrop: false,
          backdropDismiss: true,
          componentProps: {
            scene,
            devices: this.propertyDevices,
            type,
            close: () => this.closeModal()
          }
        })
        .then(m => m.present())
    },
    editSceneModal (scene) {
      return modalController
        .create({
          component: editSceneModal,
          cssClass: 'devices-modal',
          swipeToClose: true,
          showBackdrop: false,
          backdropDismiss: true,
          componentProps: {
            scene,
            close: () => this.closeModal()
          }
        })
        .then(m => m.present())
    },
    addSceneModal () {
      return modalController
        .create({
          component: addSceneModal,
          cssClass: 'devices-modal',
          swipeToClose: true,
          showBackdrop: false,
          backdropDismiss: true,
          componentProps: {
            devices: this.propertyDevices,
            close: () => this.closeModal()
          }
        })
        .then(m => m.present())
    },
    closeModal () {
      modalController.dismiss()
    },
    async presentActionSheet (scene) {
      const actionSheet = await actionSheetController
        .create({
          cssClass: 'my-custom-class',
          buttons: [
            {
              text: this.$t('Edit scene'),
              handler: () => {
                this.editSceneModal(scene)
              }
            },
            {
              text: this.$t('Delete scene'),
              role: 'destructive',
              handler: () => {
                this.deleteScene(scene)
              }
            },
            {
              text: this.$t('Cancel'),
              role: 'cancel',
              handler: () => {
                // console.log('Cancel clicked')
              }
            }
          ]
        })
      await actionSheet.present()
      const { role } = await actionSheet.onDidDismiss()
      console.log('onDidDismiss resolved with role', role)
    },
    async removeAlertConfirm (ieee, scene) {
      const alert = await alertController
        .create({
          cssClass: 'remove-device',
          header: 'Are you sure you want to remove this Device?',
          // message: 'Message <strong>text</strong>!!!',
          buttons: [
            {
              text: 'Cancel',
              role: 'cancel',
              cssClass: 'secondary',
              handler: blah => {
                console.log('Confirm Cancel:', blah)
              }
            },
            {
              text: 'Okay',
              handler: () => {
                this.removeDeviceFromScene(ieee, scene)
                console.log('Confirm Okay')
              }
            }
          ]
        })
      return alert.present()
    },
    async cannotDeleteLastScene () {
      const alert = await alertController
        .create({
          cssClass: 'remove-device',
          header: this.$t('Sorry'),
          message: this.$t('This is your default scene and cannot be removed'),
          buttons: [
            {
              text: this.$t('close'),
              role: 'cancel',
              cssClass: 'secondary',
              handler: blah => {
                console.log('Confirm Cancel:', blah)
              }
            }
          ]
        })
      return alert.present()
    },
    async cannotDeleteActiveScene () {
      const alert = await alertController
        .create({
          cssClass: 'remove-device',
          header: this.$t('Sorry'),
          message: this.$t('Active scene cannot be deleted'),
          buttons: [
            {
              text: this.$t('close'),
              role: 'cancel',
              cssClass: 'secondary',
              handler: blah => {
                console.log('Confirm Cancel:', blah)
              }
            }
          ]
        })
      return alert.present()
    }
  }
})
</script>

<style lang="scss" scoped>
@import '~@/theme/_colors';
@import "~@/theme/_backgrounds";
ion-toggle{
  padding:0;
}
.device-wrapper {
  @include bg-box-room-device;
}

.device{
    height: 180px;
    @include bg-box-room-device;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.308);
  h3{
    margin: 0;
    margin-top:10px;
    font-size: 15px;
    letter-spacing: 0.6px;
    font-weight: normal;
  }
}
</style>
