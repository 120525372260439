<template>
  <div class="background--modal" />
  <ion-header class="ion-no-border">
    <ion-toolbar color="transparent">
      <ion-button
        slot="start"
        fill="clear"
        color="transparent"
        size="large"
        class="close-modal-button"
        @click="dismissModal()"
      >
        <img
          src="../../assets/images/arrow-go-back-line.svg"
        >
      </ion-button>
      <ion-title class="text--white text-18">
        Add {{ type }} to scene
      </ion-title>
      <ion-button
        slot="end"
        fill="clear"
        color="transparent"
        size="large"
        class="close-modal-button"
        @click="dismissModal()"
      >
        <ion-label class="text--primary">
          save
        </ion-label>
      </ion-button>
    </ion-toolbar>
  </ion-header>
  <ion-content>
    <div
      v-for="group, propertyRoomId in groups"
      :key="group"
      class="text--white text-left ion-margin-top p-10"
    >
      <div class="d-flex align-items-center justify-content-between">
        <ion-label class="text--gray text-14 text--uppercase">
          {{ deviceRoom(propertyRoomId) }}
        </ion-label>
        <!-- <a @click="addAll(group)">Add all</a> -->
      </div>
      <div
        v-for="item in group"
        :key="item"
      >
        <ion-item
          class="text--white device-item"
          color="transparent"
          lines="none"
        >
          <img
            slot="start"
            style="width: 40px; margin-left: -10px;"
            :src="require('../../assets/devices/' + item.zigbeeModel + '.png')"
          >
          <ion-text slot="start">
            {{ item.zigbeeModel }}
          </ion-text>
          <ion-item
            slot="end"
            color="transparent"
            lines="none"
            :disabled="itemExists(item.ieee)"
            @click="addDeviceToScene(item)"
          >
            <i :class="`material-icons ${itemExists(item.ieee) ? 'text--gray' : 'text--primary'}`">add_circle_outline</i>
          </ion-item>
        </ion-item>
      </div>
    </div>
  </ion-content>
</template>

<script>
import {
  closeOutline,
  addCircleOutline
} from 'ionicons/icons'
import { defineComponent } from 'vue'

export default defineComponent({
  components: {
  },
  props: {
    close: { type: Function, default: null },
    scene: { type: Object, default: () => {} },
    devices: { type: Array, default: () => [] },
    type: { type: String, default: 'device' }
  },
  data () {
    return {
      closeOutline,
      addCircleOutline
    }
  },
  computed: {
    groups () {
      return groupBy(this.devices, 'propertyRoomId', this.type)
    }
  },
  methods: {
    deviceRoom (id) {
      const roomId = Number(id)
      const rooms = this.$store.state.propertyRooms
      const found = rooms.find(element => element.id === roomId)
      return found ? found.roomName : '--'
    },
    itemExists (ieee) {
      if (!this.scene.sceneSettings.devices) {
        return null
      } else {
        const found = this.scene.sceneSettings.devices.find(element => element.ieee === ieee)
        return !!found
      }
    },
    addDeviceToScene (device) {
      if (this.type === 'device') {
        this.$store.dispatch('addDeviceToScene', { device, sceneId: this.scene.id })
      } else if (this.type === 'security') {
        this.$store.dispatch('addSecurityToScene', { security: device, sceneId: this.scene.id })
      }
    },
    dismissModal () {
      this.close()
    },
    addAll (group) {
      group.forEach(device => {
        if (!this.scene.sceneSettings.includes(device)) {
          this.$store.dispatch('addDeviceToScene', { device, sceneId: this.scene.id })
        }
      })
    }
  }
})
function groupBy (array, key, type) {
  const result = {}
  console.log('=====', array)
  array.forEach(item => {
    if ((type === 'device' && item.tunable) || (type === 'security' && item.security)) {
      if (!result[item[key]]) {
        result[item[key]] = []
      }
      result[item[key]].push(item)
    } else {
      console.log('nada')
    }
  })
  return result
}
</script>

<style lang="scss" scoped>
ion-list {
  background-color: transparent;
}
</style>
