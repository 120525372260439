<template>
  <div class="background--modal" />
  <ion-header class="ion-no-border">
    <ion-toolbar color="transparent">
      <ion-button
        slot="start"
        fill="clear"
        color="transparent"
        size="large"
        class="close-modal-button"
        @click="dismissModal()"
      >
        <img
          src="../../assets/images/arrow-go-back-line.svg"
        >
      </ion-button>
      <ion-title class="text--white text-18">
        {{ scene.sceneName }}
      </ion-title>
      <ion-button
        v-if="selectedView === '1'"
        slot="end"
        fill="clear"
        color="transparent"
        size="large"
        :disabled="!sceneName || !scenePicture"
        class="close-modal-button"
        @click="saveSceneEdit"
      >
        <ion-label class="text--primary">
          {{ $t('save') }}
        </ion-label>
      </ion-button>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <div v-if="selectedView === '1'">
      <form
        class="form--dark"
        :class="platform.includes('ios') ? 'form--dark-ios' : 'form--dark-md'"
      >
        <ion-item lines="none">
          <ion-label position="floating">
            {{ $t('Scene Name') }}
          </ion-label>
          <ion-input
            v-model="sceneName"
            enterkeyhint="done"
            class="capitalize"
            type="text"
            required
            @keydown.enter="closeKeyboard"
          />
        </ion-item>
      </form>
      <ion-item
        color="transparent"
        lines="none"
        class="mt-20 rounded-corners h-100"
        :class="scenePicture"
      />
      <div class="mt-20">
        <div>
          <ion-item
            lines="none"
            color="transparent"
            detail="false"
            class="select-picture text--white"
            @click="selectedView = '2'"
          >
            <i class="material-icons picture">image</i>
          </ion-item>
          <ion-item
            lines="none"
            color="transparent"
            class="select-picture text--white"
          >
            <ion-text class="select-picture-text text--gray text-14">
              {{ $t('selectImage') }}
            </ion-text>
          </ion-item>
        </div>
      </div>
    </div>
    <div v-else-if="selectedView === '2'">
      <div
        v-for="{id, bg} in backgrounds"
        :key="id"
      >
        <div
          style="height: 100px; border-radius: 10px; margin-top: 8px;"
          :class="bg"
          @click="scenePicture = bg; selectedView = '1';"
        />
      </div>
    </div>
  </ion-content>
</template>

<script>
import {
  closeOutline,
  addCircleOutline,
  checkmarkDoneOutline,
  closeCircleOutline
} from 'ionicons/icons'
import { defineComponent } from 'vue'
import { Keyboard } from '@capacitor/keyboard'

export default defineComponent({
  components: {
  },
  props: {
    close: { type: Function, default: null },
    scene: { type: Object, default: () => {} }
  },
  data () {
    return {
      closeOutline,
      addCircleOutline,
      checkmarkDoneOutline,
      closeCircleOutline,
      selectedView: '1',
      sceneName: '',
      scenePicture: '',
      backgrounds: [
        { id: 1, bg: 'scene-background-away' },
        { id: 3, bg: 'scene-background-home' },
        { id: 4, bg: 'scene-background-sleep' },
        { id: 5, bg: 'scene-background-sleep2' },
        { id: 6, bg: 'scene-background-morning2' },
        { id: 7, bg: 'scene-background-palms' },
        { id: 8, bg: 'scene-background-tvgame' },
        { id: 9, bg: 'scene-background-disco' },
        { id: 10, bg: 'scene-background-beach' },
        { id: 11, bg: 'scene-background-fireworks' },
        { id: 12, bg: 'scene-background-good-morning' },
        { id: 13, bg: 'scene-background-good-morning2' },
        { id: 14, bg: 'scene-background-morning-coffee' },
        { id: 15, bg: 'scene-background-morning-coffee2 ' },
        { id: 16, bg: 'scene-background-night-moon' },
        { id: 17, bg: 'scene-background-party' },
        { id: 18, bg: 'scene-background-pillows' },
        { id: 19, bg: 'scene-background-relax' },
        { id: 20, bg: 'scene-background-vacation2' },
        { id: 21, bg: 'scene-background-yoga' }
      ]
    }
  },
  computed: {
    platform () {
      return this.$store.state.platform
    }
  },
  created () {
    this.sceneName = this.scene.sceneName
    this.scenePicture = this.scene.scenePicture
  },
  methods: {
    saveSceneEdit () {
      if (this.sceneName !== this.scene.sceneName) {
        if (!this.sceneName) {
          return
        }
        this.$store.dispatch('editSceneName', { sceneName: this.sceneName.replace(/(^\w|\s\w)/g, m => m.toUpperCase()), sceneId: this.scene.id })
      }
      if (this.scenePicture !== this.scene.scenePicture) {
        if (!this.scenePicture) {
          return
        }
        this.$store.dispatch('editScenePicture', { scenePicture: this.scenePicture, sceneId: this.scene.id })
      }
      this.dismissModal()
    },
    dismissModal () {
      this.close()
    },
    closeKeyboard () {
      Keyboard.hide()
    }
  }
})
</script>

<style lang="scss" scoped>
@import '~@/theme/_colors';
@import "~@/theme/_backgrounds";
ion-list {
  background-color: transparent;
}
</style>
